import { TaskDefs, TaskName } from "./taskDefs.ts";
import { TaskStateStatus } from "./types/stateTypes";



let preloadTaskState = [] as TaskStateStatus[];

export function applyTaskState() {
    var container = document.getElementById('appContainer')!;
    container.innerHTML = `
        <div class="preloadTaskState">
            <h1>Welcome!</h1>
            <p>One second while we get the app ready for you...</p>
            <table class="taskState">
                <tbody>
                    ${preloadTaskState.map(task => `
                        <tr class='status-${task.state}'>
                            <td>${
                                task.state == "current" ? 
                                    "<i class='symbols spin'>refresh" : 
                                    ("<i class='symbols'>" + task.state) 
                                }</i></td>
                            <td>
                                <h2>${TaskDefs[task.task].name}</h2>
                                <pre>${(task.result || "").toString()}</pre>
                            </td>
                        </tr>
                    `).join("")}
                    
                </tbody>
            </table>
            ${preloadTaskState.find(t => t.state == "current") ? '' :
                "<a href='/'>Restart the application</a>"
            }
        </div>
    `
}

let runners = 0;
async function runTask(task: TaskStateStatus) {
    runners++;
    task.state = "current";
    applyTaskState();
    let def = TaskDefs[task.task] as TaskDef;
    let fn = def.function;
    let cont = await fn().then(result => {
        task.result = result;
        task.state = "done";
        applyTaskState();
        return true;
    }).catch(err => {
        console.error(err);
        task.result = err;
        task.state = "error";
        applyTaskState();
        return def.nonFatal === true;
    })
    runners--;
    if (cont) stepTasks()
}
function stepTasks() {
    if (runners !== 0) return;
    let next = preloadTaskState.find(t => t.state == "pending");
    if (next) runTask(next);
}

export function queueTask(task: TaskName[], when: "queue" | "next" | "replace") {
    if (when == "queue") {
        preloadTaskState.concat(
            task.map(t => ({ task: t, state: "pending", result: null }))
        )
    } else if (when == "replace") {
        preloadTaskState = [
            ...preloadTaskState.filter(t => t.state !== "pending"),
            ...task.map(t => ({ task: t, state: "pending", result: null }))
        ] as TaskStateStatus[];
    } else if (when == "next") {
        preloadTaskState = [
            ...preloadTaskState.filter(t => t.state !== "pending"),
            ...task.map(t => ({ task: t, state: "pending", result: null })),
            ...preloadTaskState.filter(t => t.state == "pending")
        ] as TaskStateStatus[];
    }
    stepTasks();
}