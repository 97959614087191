import { AppConfig } from "../config.ts";
import { Colours, isValidColour } from "./util/colours.ts";

export const ColourSchemes = ["light", "dark", "auto"] as const;
export type ColourSchemes = typeof ColourSchemes[number];

function isValidColourScheme(scheme: string | any): ColourSchemes | false {
    return ColourSchemes.includes(scheme as any) ? scheme : false;
}

type ThemeManagerListener = 
    (accent: Colours, scheme: ColourSchemes) => void;

class ThemeManager {
    listeners = [
        (accent, scheme) => document.documentElement.className = `${accent} ${scheme}`
    ] as (ThemeManagerListener)[];
    on(fn: ThemeManagerListener) { this.listeners.push(fn); }

    constructor() {
        this.apply();
    }

    apply() {
        let accent = this.accent;
        let scheme = this.scheme;
        this.listeners.forEach(fn => 
            setTimeout(() => fn(accent, scheme),0)
        );
    }


    get accent() {
        if (AppConfig.ENV == "crm-uat") return "orange";
        return isValidColour(localStorage.getItem("accent")) || "cyan";
    }
    set accent(color: Colours) {
        localStorage.setItem("accent", color);
        this.apply();
    }


    get scheme() {
        if (AppConfig.ENV == "crm-uat") 
            return "dark" 
        return isValidColourScheme(localStorage.getItem("scheme")) || "auto";
    }
    set scheme(scheme: ColourSchemes) {
        localStorage.setItem("scheme", scheme);
        this.apply();
    }
}
export const themeManager = new ThemeManager();