import { checkError } from "./handleError";

type Methods = "GET" | "POST" | "PUT" | "DELETE" | "PATCH";
export function makeApiRequest<T>(method: Methods, url: string, body?: any): Promise<T> {
  return fetch("/api" + url, {
    method,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem("token") || "",
    },
    body: body && JSON.stringify(body),
  })
    .then(checkError)
    .then(res => res.json())
}


(window as any).makeApiRequest = makeApiRequest;